import React from 'react'
import moment from 'moment';

export default function Footer() {
    return (
        <footer>
            <div className="container mb-4">
                <div className="row">
                    <div className="col-md-12 text-center mt-4">
                        <p className="copyright">Copyright &#169; {moment().year()}. <a href="#">Benefit Specialists, LLC.</a>  All Rights Reserved.</p>
                        <p className="footer-text">
                            Benefit Specialists, LLC is not affiliated with, endorsed or sponsored by any federal agency. We are a marketing company only and we are not a financial advisor, broker dealer, investment advisory firm, insurance underwriter/carrier, insurance agency or a CPA. If you set up an appointment, the person you will be speaking with is not employed by us or by any federal agency. Further, by providing your contact information and setting up an appointment, you agree to our terms of service at <a href="/terms-of-use">Terms of Use</a></p>
                        <p className="footer-other-link mb-0"><a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-use">Terms of Use</a> | <a href="/contact">Contact Us:</a> support@{(window.location.hostname).replace("www.","")}</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
