import { makeStyles } from '@mui/styles';

const StyleSelectBox = makeStyles((theme )=> ({
  dropdownStyle: 
  {
    borderRadius: "40px !important",
    border: "0px solid rgba(0,0,0,0)",
    paddingLeft :14,
    height: 30,
    fontSize :"13px !important",
    width:"230px !important",
    "&:hover ": {
      backgroundColor: "rgba(0,0,0,.1)"
    },
  },
  
  arrowIconStyleMobile: 
  {
    backgroundColor:'#e3e3e3',
    position: "absolute",
    left: '10px',
    top: '10px'
  },

  arrowIconStyle: 
  {
    backgroundColor:'#e3e3e3',
    position: "absolute",
    left: '-30px',
    top: '10px'
  },

  WatchLaterIconBox:
  {
    display: "flex",
    marginTop:'20px',
    justifyContent:"start",
    alignContent:"center",
    color:"#595959",
    fontStyle: "normal"
  },


}));

export {StyleSelectBox};