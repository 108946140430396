import React from 'react'
import { Fragment } from 'react'
import Footer from '../components/Footer'

export default function Contact() {
  return (
    <Fragment>
        <div className="full-header">
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="w-100 d-flex align-items-center justify-content-between mob-header">
                            
                                <div className="header_em">
                                    <ul className="d-flex">
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>
                                <div className="header_em">
                                    <ul className="d-md-flex mt-1">
                                        <li><a href={"mailto:support@"+window.location.hostname}><i className="fa fa-envelope" aria-hidden="true"></i> support@{(window.location.hostname).replace("www.","")}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
                
            <div className="sec_header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav className="navbar navbar-expand-lg navbar-light d-flex justify-content-between">
                                <a className="navbar-brand" href="/"><img src={'./images/logo_main.png'} alt="" /></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse justify-content-end custom_style" id="navbarNavAltMarkup">
                                    <div className="navbar-nav">
                                    <div style={{height:'30px'}}></div>
                                    </div>
                                </div>
                                </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="contact">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mob-contect text-center">
                        <h1><span>Questions?</span><br />We're here to help.</h1>
                        <p className="contect-sub">Agents are ready to help answer your questions.</p>
                    </div>
                </div>
            </div>
        </section>

        <div className="contact_wrapper text-left">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                        <div className="icon-box_new active">
                            <div className="icon">
                                <img src="./images/loc_img.png" alt="" />
                            </div>
                            <div className="icon-content">
                                <b>Address</b>
                                <p>Benefit Specialists, LLC<br />3911 Concord Pike Unit 8030 <br/>Wilmington, DE 19803</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="icon-box_new">
                            <div className="icon">
                                <img src="./images/emil_img.png" alt="" />
                            </div>
                            <div className="icon-content">
                                <b>Email</b>
                                <p>support@{(window.location.hostname).replace("www.","")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-patten">
            <img className="w-100" src="./images/get-started-patten.png" alt="" />
        </div>
        <Footer />
    </Fragment>
  )
}
