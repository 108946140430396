import React, { useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { Typography } from '@mui/material';
import './style.scss';
import moment from 'moment';
import minusExcept from '../../utils/array';

export default function Calender({
  setDateSelect,
  dateSelected,  dates, blockTillDate, appointmentCount, holidays
}) {

  const [restrictedDate, setRestrictedDate] = useState(null);

  useEffect(()=>{
    let td = new Date();
    let bd = new Date(blockTillDate + " 23:00");
    bd.setDate(bd.getDate())
    td.setDate(td.getDate()+2);

    setRestrictedDate((td.getTime() > bd.getTime()) ? td : bd)
  }, [blockTillDate])

  const timeArray = [
    "09:00,"+appointmentCount, "09:30,"+appointmentCount,
    "10:00,"+appointmentCount, "10:30,"+appointmentCount,
    "11:00,"+appointmentCount, "11:30,"+appointmentCount,
    "12:00,"+appointmentCount, "12:30,"+appointmentCount,
    "13:00,"+appointmentCount, "13:30,"+appointmentCount,
    "14:00,"+appointmentCount, "14:30,"+appointmentCount,
    "15:00,"+appointmentCount, "15:30,"+appointmentCount,
    "16:00,"+appointmentCount, "16:30,"+appointmentCount,
    "17:00,"+appointmentCount
  ]

  const timeArrayFull = [
    // "17:30,"+appointmentCount,
    // "18:00,"+appointmentCount, "18:30,"+appointmentCount,
    // "19:00,"+appointmentCount,
  ];

  const handleDateChange = (date) => {
    setDateSelect(date)
  };

  const disableDates = (date) => {
    if(date.getDay() === 0) return true
    else if(date.getDay() === 6 ) return true
    else if (moment(date.toString()).format('YYYY-MM-DD') < moment(restrictedDate.toString()).format('YYYY-MM-DD')) return true
    else if (holidays.includes(moment(date.toString()).format('YYYY-MM-DD'))) return true
    return !checkAvailable(date)
  }
  
  const checkAvailable = (date) => {
    let d = moment(date.toString()).format('YYYY-MM-DD');
    let arr = [];
    if(dates)
      arr = dates[d] ?? []
    if(date.getDay() === 5){
      if((minusExcept(timeArray,arr)).length)
        return true;
    } else {
      if((minusExcept(timeArray.concat(timeArrayFull),arr)).length)
        return true;
    }
    return false;
  }

  const addZeroIfSingle = (value) => {
    return value < 10 ? "0"+value : value
  }

  const dateInStringFormat = (value) => {
    return (value.getFullYear() + "-" + addZeroIfSingle(value.getMonth()+1) +"-"+ addZeroIfSingle(value.getDate()))
  }

  return (
    <>
      <Typography variant="h6" fontWeight="bold" sx={{ fontStyle: 'normal' }}>
        Select a Date & Time
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {
          restrictedDate !== null && <CalendarPicker
            className={'agent_register_calendar'}
            date={dateSelected}
            defaultCalendarMonth={new Date(dateInStringFormat(restrictedDate) + " 23:00")}
            minDate={restrictedDate}
            onChange={handleDateChange}
            disablePast
            shouldDisableDate={disableDates}
            views={['day']}
          />
        }
      </LocalizationProvider>
    </>
  );
}
