import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Notify({open,onClose,severity,message}) {
 

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        message="Please Complete the form"
        open={open}
        onClose={onClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
         <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
        </Snackbar>
      
    </Stack>
  );
}
