import logoImage from "./logo.png"
import pageNotFoundImage from "./page-not-found.png"
import googleImage from "./google-calendar.png"
import outlookImage from "./outlook-calendar.png"
import iCalImage from "./iCal-calendar.png"
import emptyImage from "./search-engine.png"
import comingSoonImage from "./work-in-progress.png"
import calendar from "./calendar.png"
import us_flag from "./us_flag.png"

const Images = 	{ 
    logo: logoImage,
    page_not_found: pageNotFoundImage,
    calendar: {
        google: googleImage,
        outlook: outlookImage,
        iCal: iCalImage,
    },
    empty: emptyImage,
    coming_soon : comingSoonImage,
    calendar_icon :calendar,
    us_flag :us_flag
}

export default Images