import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import Home from './pages/Home';
import Terms from './pages/Terms';
import CalendarView from './pages/Calendar/index';
import ReSchedule from './pages/ReSchedule';
import Error404 from './pages/Error404';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contact from "./pages/Contact";

export default function App() {
    return (
        <Router>
            <Switch>
                <Route path="/setup-an-appointment" exact>
                    <CalendarView />
                </Route>
                <Route path="/complimentary-appt" exact>
                    <CalendarView />
                </Route>
                <Route path="/setup-my-appointment" exact>
                    <CalendarView />
                </Route>
                <Route path="/my-appointment" exact>
                    <CalendarView />
                </Route>
                <Route path="/reschedule" exact>
                    <ReSchedule />
                </Route>
                <Route path="/terms-of-use" exact>
                    <Terms />
                </Route>
                <Route path="/privacy-policy" exact>
                    <PrivacyPolicy />
                </Route>
                <Route path="/contact" exact>
                    <Contact />
                </Route>
                <Route path="/" exact>
                    <Home />
                </Route>
                <Route>
                    <CalendarView />
                </Route>
            </Switch>
        </Router>
    );
}