import React, { Fragment } from 'react';
import Footer from '../components/Footer';

function Home(props) {
    return (
        <Fragment>
            <div className="full-header">
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="w-100 d-flex align-items-center justify-content-between mob-header">
                                    <div className="header_em">
                                        <ul className="d-flex">
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div className="header_em">
                                        <ul className="d-md-flex">
                                            <li><a href={"mailto:support@"+window.location.hostname}><i className="fa fa-envelope" aria-hidden="true"></i> support@{(window.location.hostname).replace("www.","")}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                
                <div className="sec_header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <nav className="navbar navbar-expand-lg navbar-light d-flex justify-content-between">
                                    <a className="navbar-brand" href="/"><img src={'./images/logo_main.png'} alt="" /></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse justify-content-end custom_style" id="navbarNavAltMarkup">
                                        <div className="navbar-nav">
                                            <div style={{height:'30px'}}></div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="page-content-wrapper">
                <div className="hero_wrapper hero-bg">
                    <div className="hero_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="hero-content-wrapper">
                                        <h1>Federal Benefits & </h1>
                                        <h5>Retirement Income</h5>
                                        
                                        <div className="get_btn">
                                            <a href="/setup-an-appointment">
                                                <button type="button">Schedule a Free Consultation</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="header_style">
                                <h1>Ask Questions About</h1>
                            </div>
                        </div>
                    </div>
                    <div className="cards-wrapper">
                        <div className="row">
                            <div className="col-md-4">
                                <a href="/setup-an-appointment"><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img width={"80px"} src="./images/1.png" alt="" /></span>
                                    <h2 className='mb-0'>FERS Pension</h2>
                                </div></a>
                            </div>
                            <div className="col-md-4">
                                <a href="/setup-an-appointment"><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img width={"80px"} src="./images/5.png" alt="" /></span>
                                    <h2 className='mb-0'>Thrift Savings Plan (TSP)</h2>
                                    
                                </div></a>
                            </div>
                            {/* <div className="col-md-4">
                                <a href="/setup-an-appointment"><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img src="./images/2.png" alt="" /></span>
                                    <h2>Social Security</h2>
                                    
                                </div></a>
                            </div>
                            <div className="col-md-4">
                                <a href="/setup-an-appointment"><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img src="./images/3.png" alt="" /></span>
                                    <h2>Spousal Benefit</h2>
                                    
                                </div></a>
                            </div> */}
                            <div className="col-md-4">
                                <a href="/setup-an-appointment"><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img width={"80px"} src="./images/7.png" alt="" /></span>
                                    <h2 className='mb-0'>FEGLI (Life Insurance)</h2>
                                </div></a>
                            </div>
                            <div className="col-md-4">
                                <a href="/setup-an-appointment"><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img width={"80px"} src="./images/4.png" alt="" /></span>
                                    <h2 className='mb-0'>FERS Supplement</h2>
                                    
                                </div></a>
                            </div>
                            
                            {/* <div className="col-md-4">
                                <a href="/setup-an-appointment"><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img src="./images/6.png" alt="" /></span>
                                    <h2>SECURE Act</h2>
                                    
                                </div></a>
                            </div> */}
                            
                            <div className="col-md-4">
                                <a href="/setup-an-appointment"><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img width={"67px"} src="./images/8.png" alt="" /></span>
                                    <h2 className='mb-0'>Long Term Care</h2>
                                    
                                </div></a>
                            </div>
                            <div className="col-md-4">
                                <a href="/setup-an-appointment"><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img width={"80px"} src="./images/9.png" alt="" /></span>
                                    <h2 className='mb-0'>Tax-Free Income</h2>
                                    
                                </div></a>
                            </div>					
                        </div>
                    </div>
                </div>
                <div className="our_current_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="header_style">
                                    <h1>Our current service area includes <br/> the following states 
                                        </h1>
                                    {/* <p className="tittel-sub-tittel">
                                        We plan to rapidly expand our service area in 2023.
                                    </p> */}
                                </div>
                                <div className="map_section">
                                    <img src="./images/map.png?v=0.1" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
}

export default Home;