import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Loading = ({ isLoading, fullScreen }) => (
    <div
        className={'loading-indicator' + (fullScreen ? ' fullscreen' : '')}
        style={{
        display: isLoading ? 'flex' : 'none',
        }}
    >
        <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div>Please wait</div>
    </div>
);

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fullScreen: PropTypes.any,
};

export default Loading;
