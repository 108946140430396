export default function minusExcept(a1,a2){
    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (var j = 0; j < a2.length; j++) {
        if (a[a2[j]]) {
            delete a[a2[j]];
        }
    }

    for (var k in a) {
        diff.push(k);
    }

    return diff;
}